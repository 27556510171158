
import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ClientsPage = () => (
	<Layout>
		<h3 className="text-sm py-6 font-bold uppercase text-gray-600">Clients</h3>

		<p>
			Sign Tech is proud to have fabricated and installed thousands of signage around Sri Lanka and wherever you go in Sri Lanka, you're never too far away from one of our signage!
		</p>

		<p>
			In addition to dominating the Sri Lankan signage industry, we have also exported signage, banners, digital prints, etc. to countries such as Maldives and Mauritius. Some of our main clients include:
		</p>



		<h2 className="clients-section">Telecommunication Industry & Mobile </h2>

		<div id="infinite" className="gap-x-5 ig-sm md:ig-md lg:ig-lgx">

				<div className="excerpt">
					<img src="signtech/images/client/1_lg.jpg"/>
					<div className="title"><h2>Intex</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/2_lg.jpg"/>
					<div className="title"><h2>Samsung</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/3_lg.jpg"/>
					<div className="title"><h2>Mobitel</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/4_lg.jpg"/>
					<div className="title"><h2>Dialog</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/5_lg.jpg"/>
					<div className="title"><h2>Etisalat</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/6_lg.jpg"/>
					<div className="title"><h2>Airtel</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/7_lg.jpg"/>
					<div className="title"><h2>Hutch</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/8_lg.jpg"/>
					<div className="title"><h2>sri Lanka Telecom</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/9_lg.jpg"/>
					<div className="title"><h2>ICTA</h2></div>	
				</div>



		</div>



		<h2 className="clients-section">Hospitals</h2>

		<div id="infinite" className="gap-x-5 ig-sm md:ig-md lg:ig-lgx">

				<div className="excerpt">
					<img src="signtech/images/client/10_lg.jpg"/>
					<div className="title"><h2>Durdans Hospitals</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/11_lg.jpg"/>
					<div className="title"><h2>Central Hospital,</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/12_lg.jpg"/>
					<div className="title"><h2>Lanka Hospitals</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/13_lg.jpg"/>
					<div className="title"><h2>Nawaloka Hospital</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/14_lg.jpg"/>
					<div className="title"><h2>Asiri Hospital</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/15_lg.jpg"/>
					<div className="title"><h2>Browns Hospital</h2></div>	
				</div>

		
		
		
	</div>
	


		<h2 className="clients-section">Banking and Finance</h2>
	

		<div id="infinite" className="gap-x-5 ig-sm md:ig-md lg:ig-lgx">




		

				<div className="excerpt">
					<img src="signtech/images/client/16_lg.jpg"/>
					<div className="title"><h2>NSB</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/17_lg.jpg"/>
					<div className="title"><h2>Seylan Bank Plc</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/18_lg.jpg"/>
					<div className="title"><h2>Bank of Ceylon</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/19_lg.jpg"/>
					<div className="title"><h2>Peoples’ Bank</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/20_lg.jpg"/>
					<div className="title"><h2>HNB</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/21_lg.jpg"/>
					<div className="title"><h2>Sampath Bank</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/22_lg.jpg"/>
					<div className="title"><h2>Standard Chartered Bank</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/23_lg.jpg"/>
					<div className="title"><h2>Lanka Puthra Finance Corporation</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/24_lg.jpg"/>
					<div className="title"><h2>Central Bank of Sri Lanka</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/25_lg.jpg"/>
					<div className="title"><h2>Nations Trust Bank</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/26_lg.jpg"/>
					<div className="title"><h2>Deutsche Bank</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/27_lg.jpg"/>
					<div className="title"><h2>MBSL</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/28_lg.jpg"/>
					<div className="title"><h2>NDB</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/29_lg.jpg"/>
					<div className="title"><h2>Money gram</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/30_lg.jpg"/>
					<div className="title"><h2>LOLC</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/31_lg.jpg"/>
					<div className="title"><h2>ETI</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/32_lg.jpg"/>
					<div className="title"><h2>PLC</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/33_lg.jpg"/>
					<div className="title"><h2>CLC</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/34_lg.jpg"/>
					<div className="title"><h2>Xpress Money</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/35_lg.jpg"/>
					<div className="title"><h2> Habib Bank</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/36_lg.jpg"/>
					<div className="title"><h2>State Mortgage & Investment Bank</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/37_lg.jpg"/>
					<div className="title"><h2>HSBC</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/38_lg.jpg"/>
					<div className="title"><h2>Sri Lanka Savings Bank</h2></div>	
				</div>

		
		
		
	</div>
	


		<h2 className="clients-section">Energy Sector</h2>
	

		<div id="infinite" className="gap-x-5 ig-sm md:ig-md lg:ig-lgx">




		

				<div className="excerpt">
					<img src="signtech/images/client/39_lg.jpg"/>
					<div className="title"><h2>CEYPETCO</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/40_lg.jpg"/>
					<div className="title"><h2>Lanka IOC</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/41_lg.jpg"/>
					<div className="title"><h2>Ceylon Electricity Board (CEB)</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/42_lg.jpg"/>
					<div className="title"><h2>LECO</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/43_lg.jpg"/>
					<div className="title"><h2>Laugfs Gas</h2></div>	
				</div>

		
		
		
	</div>
	


	
		<h2 className="clients-section">Home Appliances</h2>

		<div id="infinite" className="gap-x-5 ig-sm md:ig-md lg:ig-lgx">




		

				<div className="excerpt">
					<img src="signtech/images/client/44_lg.jpg"/>
					<div className="title"><h2>Samsung Electronics </h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/45_lg.jpg"/>
					<div className="title"><h2>Softlogic</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/46_lg.jpg"/>
					<div className="title"><h2>Abans</h2></div>	
				</div>

		
		
		
	</div>
	


	
		<h2 className="clients-section">Automobiles</h2>

		<div id="infinite" className="gap-x-5 ig-sm md:ig-md lg:ig-lgx">




		

				<div className="excerpt">
					<img src="signtech/images/client/47_lg.jpg"/>
					<div className="title"><h2>DIMO</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/48_lg.jpg"/>
					<div className="title"><h2>AMW</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/49_lg.jpg"/>
					<div className="title"><h2>Micro</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/50_lg.jpg"/>
					<div className="title"><h2>Indra Traders</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/51_lg.jpg"/>
					<div className="title"><h2>MAZDA</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/52_lg.jpg"/>
					<div className="title"><h2>United Motors</h2></div>	
				</div>

		
		
		
	</div>
	


		<h2 className="clients-section">Airlines and Ports</h2>
	

		<div id="infinite" className="gap-x-5 ig-sm md:ig-md lg:ig-lgx">




		

				<div className="excerpt">
					<img src="signtech/images/client/53_lg.jpg"/>
					<div className="title"><h2>Sri Lankan Airlines</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/54_lg.jpg"/>
					<div className="title"><h2>Oman Air</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/55_lg.jpg"/>
					<div className="title"><h2>Cinnamon Air</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/56_lg.jpg"/>
					<div className="title"><h2>Helitours</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/57_lg.jpg"/>
					<div className="title"><h2>Airport and Aviation</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/58_lg.jpg"/>
					<div className="title"><h2>Sri Lanka Ports Authority</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/59_lg.jpg"/>
					<div className="title"><h2>Colombo Dockyard</h2></div>	
				</div>

		
		
		
	</div>
	


		<h2 className="clients-section">Hospitality Industry</h2>
	

		<div id="infinite" className="gap-x-5 ig-sm md:ig-md lg:ig-lgx">



				<div className="excerpt">
					<img src="signtech/images/client/60_lg.jpg"/>
					<div className="title"><h2>Shangri-la Hotels - Sri Lanka</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/61_lg.jpg"/>
					<div className="title"><h2>Cinnamon Hotels & Resorts</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/62_lg.jpg"/>
					<div className="title"><h2>Aitken Spence</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/63_lg.jpg"/>
					<div className="title"><h2>ri Lanka Tourism Promotions Board</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/64_lg.jpg"/>
					<div className="title"><h2>TAJ Hotel</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/65_lg.jpg"/>
					<div className="title"><h2>LOLC Leisure Group </h2></div>	
				</div>

		
		
		
	</div>
	


	
		<h2 className="clients-section">Construction Industry</h2>

		<div id="infinite" className="gap-x-5 ig-sm md:ig-md lg:ig-lgx">

				<div className="excerpt">
					<img src="signtech/images/client/66_lg.jpg"/>
					<div className="title"><h2>Nawaloka Construction</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/67_lg.jpg"/>
					<div className="title"><h2>Sanken</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/68_lg.jpg"/>
					<div className="title"><h2>Maga</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/69_lg.jpg"/>
					<div className="title"><h2>Central Engineering Consultancy Bureau (CECB)</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/70_lg.jpg"/>
					<div className="title"><h2>China Harbour</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/71_lg.jpg"/>
					<div className="title"><h2>Tokyo Cement</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/72_lg.jpg"/>
					<div className="title"><h2>ICTAD</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/73_lg.jpg"/>
					<div className="title"><h2>Access Engineering</h2></div>	
				</div>

		
		
		
	</div>
	


		<h2 className="clients-section">Educational Institutes</h2>
	

		<div id="infinite" className="gap-x-5 ig-sm md:ig-md lg:ig-lgx">


				<div className="excerpt">
					<img src="signtech/images/client/74_lg.jpg"/>
					<div className="title"><h2>Nawaloka College of Higher studies</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/75_lg.jpg"/>
					<div className="title"><h2>APIIT</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/76_lg.jpg"/>
					<div className="title"><h2>SLIIT</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/77_lg.jpg"/>
					<div className="title"><h2>SLIM</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/78_lg.jpg"/>
					<div className="title"><h2>Raffles Design University</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/79_lg.jpg"/>
					<div className="title"><h2>University of Sri Jayawaradenapura</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/80_lg.jpg"/>
					<div className="title"><h2> University of Sabaragamuwa</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/81_lg.jpg"/>
					<div className="title"><h2> University of Moratuwa</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/82_lg.jpg"/>
					<div className="title"><h2>University of Wayamba</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/83_lg.jpg"/>
					<div className="title"><h2>University of Kelaniya</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/84_lg.jpg"/>
					<div className="title"><h2>University of Colombo</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/85_lg.jpg"/>
					<div className="title"><h2>ANC</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/86_lg.jpg"/>
					<div className="title"><h2>National Science Foundation</h2></div>	
				</div>

		
		
		
	</div>
	


		<h2 className="clients-section">FMCG</h2>
	

		<div id="infinite" className="gap-x-5 ig-sm md:ig-md lg:ig-lgx">


				<div className="excerpt">
					<img src="signtech/images/client/87_lg.jpg"/>
					<div className="title"><h2>Unilevers</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/88_lg.jpg"/>
					<div className="title"><h2>Hemas</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/89_lg.jpg"/>
					<div className="title"><h2>Fonterra</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/90_lg.jpg"/>
					<div className="title"><h2>Swadeshi</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/91_lg.jpg"/>
					<div className="title"><h2>Sunshine Holdings</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/92_lg.jpg"/>
					<div className="title"><h2>Sadaharitha Plantation</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/93_lg.jpg"/>
					<div className="title"><h2>Expo Lanka</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/94_lg.jpg"/>
					<div className="title"><h2>Dankotuwa Porcelain</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/95_lg.jpg"/>
					<div className="title"><h2>Lanka Tiles</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/96_lg.jpg"/>
					<div className="title"><h2>Hafele</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/97_lg.jpg"/>
					<div className="title"><h2>Rocell</h2></div>	
				</div>

		
		
		
	</div>
	


		<h2 className="clients-section">Apparel </h2>
	

		<div id="infinite" className="gap-x-5 ig-sm md:ig-md lg:ig-lgx">



				<div className="excerpt">
					<img src="signtech/images/client/98_lg.jpg"/>
					<div className="title"><h2>Stone and String</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/99_lg.jpg"/>
					<div className="title"><h2>Vouge Jewellers</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/100_lg.jpg"/>
					<div className="title"><h2>Brandix</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/101_lg.jpg"/>
					<div className="title"><h2>MAS</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/102_lg.jpg"/>
					<div className="title"><h2>John Keells</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/103_lg.jpg"/>
					<div className="title"><h2>Fashion Optic</h2></div>	
				</div>

		
		
		
	</div>
	


		<h2 className="clients-section">Information Technology Sector</h2>
	

		<div id="infinite" className="gap-x-5 ig-sm md:ig-md lg:ig-lgx">

		

				<div className="excerpt">
					<img src="signtech/images/client/104_lg.jpg"/>
					<div className="title"><h2>WSO2</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/105_lg.jpg"/>
					<div className="title"><h2>Virtusa</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/106_lg.jpg"/>
					<div className="title"><h2>Codegen</h2></div>	
				</div>

		

				<div className="excerpt">
					<img src="signtech/images/client/107_lg.jpg"/>
					<div className="title"><h2>S.L.I.I.T</h2></div>	
				</div>

		
		
		
	</div>


	</Layout>
)

export default ClientsPage